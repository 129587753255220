<section class="content-meta pt-3" id="praktische-informatie">
  <ul class="location-list">
    <li class="location-list__item" *ngFor="let location of locations">
      <a
        class="d-inline-flex align-items-center"
        [href]="'https://www.google.com/maps/search/' + location.lat + ',' + location.lng"
        aria-label="bekijk locatie op google maps"
        target="_blank"
      >
        <app-icon anchor="icon-map-marker" class="me-1"></app-icon>

        <span>
          <ng-container *ngIf="!entireArea">
            {{ location.label || ('bekijk-op-kaart' | fromDictionary) }}
          </ng-container>
          <ng-container *ngIf="entireArea">{{ 'gehele-ijssel' | fromDictionary }}</ng-container>
        </span>
      </a>
    </li>
  </ul>
</section>
