import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { HeaderComponent } from './components/header/header.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { IconModule } from '@teamfoster/sdk/icon';
import { GridOverlayComponent } from './components/grid-overlay/grid-overlay.component';
import { IconComponent } from './components/icon/icon.component';
import { ImageComponent } from './components/image/image.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { SeoModule } from '../seo/seo.module';
import { AlertComponent } from './components/alert/alert.component';
import { A11yModule } from '@angular/cdk/a11y';
import { BgWaveComponent } from './components/bg-wave/bg-wave.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ActionsCardComponent } from './components/actions-card/actions-card.component';
import { WaveCtaComponent } from './components/wave-cta/wave-cta.component';

import { InitiativeCardComponent } from './components/initiative-card/initiative-card.component';
import { StoryCardComponent } from './components/story-card/story-card.component';
import { RelatedInitiativesComponent } from './components/related-initiatives/related-initiatives.component';
import { RelatedStoriesComponent } from './components/related-stories/related-stories.component';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { EventCardComponent } from './components/event-card/event-card.component';
import { CastPipe } from './pipes/cast.pipe';
import { PageCardComponent } from './components/page-card/page-card.component';
import { RelatedEventsComponent } from './components/related-events/related-events.component';
import { TeaserCardComponent } from './components/teaser-card/teaser-card.component';
import { ImageModule } from '@teamfoster/sdk/image';
import { ParticipateDialogComponent } from '../standalone/components/participate-dialog/participate-dialog.component';
import { RelatedContentComponent } from './components/related-content/related-content.component';
import { SliderV2Component } from './components/slider-v2/slider-v2.component';
import { ContentMetaInfoComponent } from './components/content-meta-info/content-meta-info.component';

const moduleExports = [
  LoaderIndicatorComponent,
  HeaderComponent,
  TopbarComponent,
  FooterComponent,
  GridOverlayComponent,
  IconComponent,
  ImageComponent,
  AlertComponent,
  BgWaveComponent,
  BreadcrumbsComponent,
  ContactCardComponent,
  ConfirmComponent,

  StoryCardComponent,
  EventCardComponent,
  ActionsCardComponent,
  WaveCtaComponent,
  InitiativeCardComponent,
  RelatedInitiativesComponent,
  RelatedStoriesComponent,
  CastPipe,
  PageCardComponent,
  RelatedEventsComponent,
  TeaserCardComponent,
  RelatedContentComponent,
  ContentMetaInfoComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    InViewModule,
    TextUtilityModule,
    Html5VideoPlayerModule,
    IconModule,
    ImageModule,
    DictionaryNgrxModule,
    SeoModule,
    A11yModule,
    MatDialogModule,
    CookieNgrxModule,
    ParticipateDialogComponent,
    SliderV2Component,
  ],
  declarations: [...moduleExports],
  exports: [...moduleExports],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
