import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { findNearest, getDistance } from 'geolib';
import { SearchResult } from 'src/app/search/models';
import { Initiative } from '../../../initiatives/models';

@Component({
  selector: 'app-initiative-card',
  templateUrl: './initiative-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitiativeCardComponent {
  @Input() initiative!: Initiative;
  @Input() location!: GeolocationPosition | undefined | null;
  @Input() inverted = false;
  @Input() showIntro = true;
  @Input() label?: string;

  getDistance() {
    if (!this.location || !this.initiative.locations?.length) {
      return undefined;
    }

    const nearest = findNearest(
      [this.location.coords.latitude, this.location.coords.longitude],
      this.initiative.locations.map(l => [l.lat, l.lng])
    );
    return getDistance(nearest, [this.location.coords.latitude, this.location.coords.longitude]);
  }
}
