<section class="related-initiatives">
  <header class="related-initiatives__header mb-0 mb-md-4">
    <span class="type-body-md text-secondary fw-bold w-100">{{ subtitle || ('gerelateerde-initiatieven-subtitel' | fromDictionary) }}</span>
    <h1 class="related-initiatives__title h2 text-primary">{{ title || ('gerelateerde-initiatieven-titel' | fromDictionary) }}</h1>
    <a [routerLink]="['/initiatieven']" class="button button-link button--primary related-initiatives__more">
      <span>{{ 'gerelateerde-initiatieven-alle-initiatieven-link-titel' | fromDictionary }}</span>
      <app-icon anchor="icon-arrow-right"></app-icon>
    </a>
  </header>

  <main class="related-initiatives__main py-2 py-md-4" *ngIf="content">
    <!--<app-slider [cursorArrow]="false" [total]="content.length" [config]="sliderConfig">
      <div class="col-12 col-md-6 col-lg-4" *ngFor="let content of content; trackBy: trackByFn" [ngSwitch]="content.type">
        <app-initiative-card [inverted]="true" [initiative]="asInitiative(content)" *ngSwitchCase="'Initiative'"></app-initiative-card>
        <app-story-card [story]="asStory(content)" *ngSwitchCase="'Story'"></app-story-card>
        <app-event-card [inverted]="true" [event]="asEvent(content)" *ngSwitchCase="'Event'"></app-event-card>
      </div>
    </app-slider>-->

    <app-slider-v2>
      <swiper-slide class="swiper-slide" [ngSwitch]="content.type" *ngFor="let content of content; trackBy: trackByFn">
        <app-initiative-card
          class="swiper-slide__card"
          [inverted]="true"
          [initiative]="asInitiative(content)"
          *ngSwitchCase="'Initiative'"
        ></app-initiative-card>
        <app-story-card class="swiper-slide__card" [story]="asStory(content)" *ngSwitchCase="'Story'"></app-story-card>
        <app-event-card class="swiper-slide__card" [inverted]="true" [event]="asEvent(content)" *ngSwitchCase="'Event'"></app-event-card>
      </swiper-slide>
    </app-slider-v2>
  </main>
</section>
