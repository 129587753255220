<article class="event-card">
  <a [routerLink]="event.url" class="block-link event-card__inner" [attr.aria-label]="'meer over ' + event.title">
    <app-image
      class="event-card__img"
      [alt]="event.image.caption || event.title"
      [image]="event.image"
      [sizes]="{ md: 4, xl: 3 }"
      [imageSizes]="[300, 500, 800]"
      [width]="700"
      [height]="900"
      [objectFitMode]="true"
    ></app-image>

    <main class="event-card__content p-3 p-md-4">
      <div class="event-card__content-spacing">
        <div class="event-card__meta">
          <ul class="theme-list">
            <li class="theme-list__item" *ngFor="let item of event.themes">
              <button
                tabindex="-1"
                class="chip chip--light chip-icon chip--sm"
                [attr.aria-label]="'categorie ' + item.title"
                [title]="item.title"
              >
                <app-icon [anchor]="item.icon"></app-icon>
              </button>
            </li>
          </ul>
          <div>
            <span *ngIf="label" class="chip chip--primary chip--label d-none d-sm-inline-flex mb-2">{{ label }}</span>
            <em class="event-card__date py-3 px-4 event-date">
              <!-- event-card__date__day  text-primary event-card__date__month-->
              <span class="event-date__day">{{ event.start | date : 'dd' }}</span>
              <span class="event-date__month">{{ getMonth(event.start) }}</span>

              <ng-container *ngIf="event.end && (event.end | date : 'dd') !== (event.start | date : 'dd')">
                <span class="type-body-xxs text-primary mt-1">tot</span>
                <span class="event-date__day">{{ event.end | date : 'dd' }}</span>
                <span class="event-date__month">{{ getMonth(event.end) }}</span>
              </ng-container>
            </em>
          </div>
        </div>
        <div class="event-card__content-titles">
          <strong class="event-card__content-subtitle text-secondary mb-1" *ngIf="event.initiative">
            {{ event.initiative.title || event.subtitle }}
          </strong>
          <strong class="event-card__content-subtitle text-secondary mb-1" *ngIf="event.subtitle && !event.initiative">
            {{ event.subtitle }}
          </strong>
          <h2 class="event-card__content-title text-light font-secondary h6 mb-0" [attr.title]="event.title">
            {{ event.title | truncate : 60 }}
          </h2>

          <span class="chip chip--label chip--tertiary chip--sm mt-2" *ngIf="getDistance() as metres">
            {{ metres / 1000 | number : '1.0-1' }}km
          </span>
        </div>
      </div>
    </main>
  </a>
</article>
